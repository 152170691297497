import { LabeledEnumModel } from '../models/enum/labeledEnum.model';

export const AgesEmissionClass: LabeledEnumModel = {
	// Ages emission classes
	'EURO 0': { label: 'EURO 0' },
	'EURO 1': { label: 'EURO 1' },
	'EURO 2': { label: 'EURO 2' },
	'EURO 3': { label: 'EURO 3' },
	'EURO 4': { label: 'EURO 4' },
	'EURO 5': { label: 'EURO 5' },
	'EURO 6': { label: 'EURO 6' },
	'CO2 Klasse 1 mit EURO 0': { label: 'CO₂ Klasse 1 mit EURO 0' },
	'CO2 Klasse 1 mit EURO 1': { label: 'CO₂ Klasse 1 mit EURO 1' },
	'CO2 Klasse 1 mit EURO 2': { label: 'CO₂ Klasse 1 mit EURO 2' },
	'CO2 Klasse 1 mit EURO 3': { label: 'CO₂ Klasse 1 mit EURO 3' },
	'CO2 Klasse 1 mit EURO 4': { label: 'CO₂ Klasse 1 mit EURO 4' },
	'CO2 Klasse 1 mit EURO 5': { label: 'CO₂ Klasse 1 mit EURO 5' },
	'CO2 Klasse 1 mit EURO 6': { label: 'CO₂ Klasse 1 mit EURO 6' },
	'CO2 Klasse 2': { label: 'CO₂ Klasse 2' },
	'CO2 Klasse 3': { label: 'CO₂ Klasse 3' },
	'CO2 Klasse 4': { label: 'CO₂ Klasse 4' },
	'CO2 Klasse 5': { label: 'CO₂ Klasse 5' },
};
